import React, { useEffect, useState } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import SECTIONS from 'src/utils/sectionConstants';
import YouTubePlayer from 'components/YoutubePlayer';
import ListOfCards from 'features/listOfCards';
import GatsbyImage from 'components/GatsbyImage/GatsbyImage';
import { generateFluidImage } from 'utils/image';
import slugify from 'slugify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'reactstrap';
import styles from './RichText.module.scss';

const tooltipDefaultValue = 'Copy to clipboard';
const tooltipCopiedValue = 'Copied to clipboard';

const RichText = ({ json, headline, location }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipText, setTooltipText] = useState(tooltipDefaultValue);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const copyText = slug =>
    navigator.clipboard
      .writeText(`${window.location.origin}${window.location.pathname}#${slug}`)
      .then(() => {
        setTooltipText(tooltipCopiedValue);
        setTimeout(() => {
          toggleTooltip();
          setTooltipText(tooltipDefaultValue);
        }, 1000);
      });

  // Check on "embedded-entry-block" to render the correct component
  const renderComponent = node => {
    const { contentful_id } = node.data.target.sys.contentType.sys;
    const data = node.data.target.fields;
    switch (contentful_id) {
      case SECTIONS.webVideoMedia:
        const url = data?.videoUrl['en-US'];
        return (
          <div className={styles.videoWrapper}>
            <YouTubePlayer url={url} />
          </div>
        );

      case SECTIONS.webSectionWrapper:
        const subType =
          data.component['en-US'].sys.contentType.sys.contentful_id;
        const subData = data.component['en-US'].fields;
        switch (subType) {
          case SECTIONS.webCard:
            const card = {
              style: subData.style['en-US'],
              title: subData.title['en-US'],
              textAlign: subData.textAlign['en-US'],
              position: data.position['en-US'],
              description: {
                childMarkdownRemark: {
                  html: subData.description ? subData.description['en-US'] : '',
                },
              },
            };
            return (
              <div>
                <ListOfCards cards={[card]} />
              </div>
            );
          default:
            return '';
        }

      default:
        return '';
    }
  };

  const renderAssetComponent = node => {
    const file = node?.data?.target?.fields?.file['en-US'];
    if (file?.contentType.startsWith('image')) {
      const data = file?.url;
      const width = file?.details?.image?.width;
      const height = file?.details?.image?.height;
      return (
        <div className={styles.imageWrapper}>
          <GatsbyImage
            image={generateFluidImage(1000, data, 90, width, height)}
            className={styles.img}
          />
        </div>
      );
    }
  };

  const renderHeadlinesLinks = slug => {
    return (
      <>
        <FontAwesomeIcon
          className={`${styles.linkingIcon} mr-2`}
          icon={faLink}
          id={`link-icon`}
          onClick={() => copyText(slug)}
        />
      </>
    );
  };

  useEffect(() => {
    const isBrowser = typeof window !== undefined;
    if (isBrowser) {
      const { hash } = window.location;
      if (!hash) return;
      window.requestAnimationFrame(() => {
        const anchor = document.querySelector(hash);
        const offset = anchor.getBoundingClientRect().top + window.scrollY;
        window.scroll({ top: offset - 80, left: 0 });
      });
    }
  }, [location?.hash]);
  // Options configuration to render custom components for each block type
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        return renderComponent(node);
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return renderAssetComponent(node);
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        const slug = slugify(node?.content[0]?.value, {
          strict: true,
          lower: true,
        });
        return (
          <h1 id={slug} className={styles.headlines}>
            {renderHeadlinesLinks(slug)}
            {children}
          </h1>
        );
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        const slug = slugify(node?.content[0]?.value, {
          strict: true,
          lower: true,
        });
        return (
          <h2 id={slug} className={styles.headlines}>
            {renderHeadlinesLinks(slug)}
            {children}
          </h2>
        );
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        const slug = slugify(node?.content[0]?.value, {
          strict: true,
        });
        return (
          <h3 id={slug} className={styles.headlines}>
            {renderHeadlinesLinks(slug)}
            {children}
          </h3>
        );
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        const slug = slugify(node?.content[0]?.value, {
          strict: true,
          lower: true,
        });
        return (
          <h4 id={slug} className={styles.headlines}>
            {renderHeadlinesLinks(slug)}
            {children}
          </h4>
        );
      },
      [BLOCKS.HEADING_5]: (node, children) => {
        const slug = slugify(node?.content[0]?.value, {
          strict: true,
          lower: true,
        });
        return (
          <h5 id={slug} className={styles.headlines}>
            {renderHeadlinesLinks(slug)}
            {children}
          </h5>
        );
      },
      [BLOCKS.HEADING_6]: (node, children) => {
        const slug = slugify(node?.content[0]?.value, {
          strict: true,
          lower: true,
        });
        return (
          <h6 id={slug} className={styles.headlines}>
            {renderHeadlinesLinks(slug)}
            {children}
          </h6>
        );
      },
    },
  };

  return (
    <div className={styles.richTextWrapper}>
      <div className={styles.richTextContent}>
        {headline && <h1>{headline}</h1>}
        {documentToReactComponents(json, options)}
      </div>
      <span id="link-icon" className="d-none"></span>
      <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        target={`link-icon`}
        toggle={toggleTooltip}
      >
        {tooltipText}
      </Tooltip>
    </div>
  );
};

export default RichText;
